import {AfterContentInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { TopLanguageService } from 'src/app/core/shared/language.service';

@Component({
  selector: 'top-dashboard-signature',
  templateUrl: './top-signature.component.html',
  styleUrls: ['./top-signature.component.scss']
})
export class TopSignatureComponent implements OnInit, AfterContentInit {

  public userId: string;
  public language: string;
  public elementUrl = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translateService: TopLanguageService) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.userId = param.userId;
      this.language = param.language;

      this.translateService.current.subscribe((lang) => {
        this.language = lang;
      });
    });
  }

  ngAfterContentInit(): void {
    this.elementUrl = environment.webComponents.topSignatureUrl;
  }
}
