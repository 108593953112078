import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { NotFoundComponent } from './error/not.found.component';
import { HomeComponent } from './home/home.component';
// import { NavComponent } from './nav/nav.component';

const routes: Routes = [
  // {
  //   path: 'profile',
  //   component: ProfileComponent
  //   canActivate: [MsalGuard]
  // },
  {
    path: '',
    component: HomeComponent
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}]
})
export class AppRoutingModule {
}
