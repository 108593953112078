import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private snackbar: SnackBarService
  ) {
  }

  public getRecIdByMailId(mailId: string): Observable<string> {
    const url = `${environment.graphConnectorApi}recIdBymailid?emailid=${mailId}`;
    return this.http.get<string>(url).pipe(
      catchError((error) => {
        this.snackbar.open(error.error.errorCode, error.error.errorText);
        return throwError(error);
      }));
  }

  public getGroupsByMailId(mailId: string): Observable<string[]> {
    const url = `${environment.graphConnectorApi}groupsBymailid?emailid=${mailId}`;
    return this.http.get<string[]>(url).pipe(
      catchError((error) => {
        this.snackbar.open(error.error.errorCode, error.error.errorText);
        return throwError(error);
      }));
  }
}
