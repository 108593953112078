<mat-toolbar color="primary" class="toolbar">
  <button aria-label="top-it icon"
          mat-icon-button
          type="button">
    <mat-icon aria-hidden="true" class="toolbar-icon topBase"></mat-icon>
    <span class="version">{{version}}</span>
  </button>
  <span class="example-spacer"></span>
  <span *ngIf="loginDisplay" class="emailAddress">{{ this.emailAddress }}</span>
  <button mat-button *ngIf="loginDisplay" (click)="logout()">Logout</button>
</mat-toolbar>
<mat-toolbar color="primary" class="mat-toolbar">
  <div *ngIf="true" class="toggleBox">
    <button (click)="drawer.toggle()"
            aria-label="Toggle sidenav"
            mat-icon-button
            class="menu"
            type="button">
      <mat-icon
        aria-label="Side nav toggle icon">{{drawer.opened ? 'navigate_before' : 'keyboard_arrow_right'}}</mat-icon>
    </button>
  </div>
  <button (click)="setTabDashboard()"
          aria-label="top-it icon"
          mat-icon-button
          type="button">
    <mat-icon aria-hidden="true" class="toolbar-icon topBase"></mat-icon>
    <span class="version">{{version}}</span>
  </button>

  <span class="example-spacer"></span>


  <mat-form-field class="language-form">
    <mat-select class="language-option" [value]="currentLanguage$ | async"
                (valueChange)="useLanguage($event)">
      <mat-option value='de'>{{'GERMAN' | translate}}</mat-option>
      <mat-option value='en'>{{'ENGLISH' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
               [opened]="loginDisplay"
               class="sidenav"
               fixedInViewport="true"
               mode="side"
               position="start">
    <mat-nav-list>
      <!--      <div [ngStyle]="{'display':checkAccessRights('topSearch') ?  'block' : 'none' }" class="sidenav-item" routerLinkActive="mat-active">-->
      <div [ngStyle]="{'display':true ?  'block' : 'none' }" class="sidenav-item" routerLinkActive="mat-active">
        <mat-icon class="material-icons sidenav-icons"
                  (click)='loadWebComponent("{\"url\": \"topSearch\", \"label\": \"topSearch\", \"attributeName\": \"topSearch\"}")'
                  type="icon">search
        </mat-icon>
        <p class="sidenav-title">topSearch</p>
      </div>

      <div [ngStyle]="{'display':checkAccessRights('topSignature') ?  'block' : 'none'}" class="sidenav-item"
           routerLinkActive="mat-active">
        <mat-icon class="material-icons sidenav-icons"
                  (click)='loadWebComponent("{\"url\": \"topSignature\", \"label\": \"topSignature\", \"attributeName\": \"topSignature\"}")'
                  type="icon">create
        </mat-icon>
        <p class="sidenav-title">topSignature</p>
      </div>

      <!--      <div [ngStyle]="{'display':checkAccessRights('topAdvert') ?  'block' : 'none'}" class="sidenav-item" routerLinkActive="mat-active">-->
      <div [ngStyle]="{'display':true ?  'block' : 'none'}" class="sidenav-item" routerLinkActive="mat-active">
        <mat-icon class="material-icons sidenav-icons"
                  (click)='loadWebComponent("{\"url\": \"topAdvert\", \"label\": \"topAdvert\", \"attributeName\": \"topAdvert\"}")'
                  type="icon">font_download
        </mat-icon>
        <p class="sidenav-title">topAdvert</p>
      </div>

      <div [ngStyle]="{'display':checkAccessRights('topCompanies') ?  'block' : 'none' }" class="sidenav-item"
           routerLinkActive="mat-active">
        <mat-icon class="material-icons sidenav-icons" type="icon" [matMenuTriggerFor]="aboveMenu">apartment
        </mat-icon>
        <p class="sidenav-title">topCompanies</p>
        <mat-menu #aboveMenu="matMenu" yPosition="above">
          <div>
            <button mat-menu-item
                    (click)='loadWebComponent("{\"url\": \"lcsKrones\", \"label\": \"LCS\", \"attributeName\": \"lcsKrones\"}")'>
              <mat-icon type="icon">
                card_travel
              </mat-icon>
              <span>LCS</span>
            </button>
          </div>
          <button mat-menu-item
                  (click)='loadWebComponent("{\"url\": \"keiner\", \"label\": \"für weitere Firmen\", \"attributeName\": \"keiner\"}")'>
            <mat-icon type="icon">
              developer_mode
            </mat-icon>
            <span>{{'OTHER' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div [ngClass]="{'content-container': loginDisplay,'content-container-image': !loginDisplay}">
      <mat-tab-group [(selectedIndex)]="selectedTab">
        <mat-tab *ngFor="let tab of tabs; index as index">
          <ng-template mat-tab-label>
            {{tab.label}}
            <mat-icon *ngIf="index > 0" class="tab-icon" (click)="closeTab(index)">cancel</mat-icon>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <div class="tab-content-scroll">
        <div *ngFor="let tab of tabs; index as index">
          <div *ngIf="tab.attributeName == 'dashboard'">
            <div class="toolbox-container" [ngStyle]="{'display': selectedTab == index ? null : 'none'}">
              <div class="toolboxes">
                <div *ngFor="let box of boxes, index as i" class="toolbox-headline">
                  <h1>{{ box.name | translate }}</h1>
                  <div class="toolbox">
                    <div class="toolbox-header">
                      <h2> {{box.headline | translate }} </h2>
                    </div>
                    <ul class="toolbox-content">
                      <li *ngFor="let item of box.items"
                          (click)="openToolInNewWindow(item.link)">{{ item.name }}</li>
                    </ul>
                    <span *ngIf="i === 1" class="hint">{{ 'BOXES.SEARCHPORTAL.HINT' | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="recId-display">
                <span>{{ 'recId: ' + userId }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="tab.attributeName == 'topSignature'">
            <div [ngStyle]="{'display':selectedTab == index ? null : 'none'}">
              <ng-template #loading>{{'LOADING_COMPONENT' | translate}}</ng-template>
              <ng-template #error>{{'LOADING_COMPONENT_ERROR' | translate}}</ng-template>
              <ax-lazy-element [userid]="userId" [language]="selectedLanguage"
                               *axLazyElementDynamic="'top-signature-dashboard' loadingTemplate: loading; errorTemplate: error">
              </ax-lazy-element>
            </div>
          </div>
          <div *ngIf="tab.attributeName == 'topSearch'">
            <div [ngStyle]="{'display':selectedTab == index ? null : 'none'}">
              <ng-template #loading>{{'LOADING_COMPONENT' | translate}}</ng-template>
              <ng-template #error>{{'LOADING_COMPONENT_ERROR' | translate}}</ng-template>
              <ax-lazy-element [userid]="userId" [language]="selectedLanguage" [step]="tab.step" [routeparameter]="tab.routeparameter"
                               *axLazyElementDynamic="'top-search-dashboard' loadingTemplate: loading; errorTemplate: error">
              </ax-lazy-element>
            </div>
          </div>
          <div *ngIf="tab.attributeName == 'topAdvert'">
            <div [ngStyle]="{'display':selectedTab == index ? null : 'none'}">
              <ng-template #loading>{{'LOADING_COMPONENT' | translate}}</ng-template>
              <ng-template #error>{{'LOADING_COMPONENT_ERROR' | translate}}</ng-template>
              <ax-lazy-element [userid]="userId" [language]="selectedLanguage" [step]="0" [routeparameter]="tab.routeparameter"
                               [authorization]="authorization"
                               *axLazyElementDynamic="'top-advert' loadingTemplate: loading;
                                              errorTemplate: error">
              </ax-lazy-element>
            </div>
          </div>
          <div *ngIf="tab.attributeName == 'lcsKrones'">
            <div [ngStyle]="{'display':selectedTab == index ? null : 'none'}">
              <ng-template #loading>{{'LOADING_COMPONENT' | translate}}</ng-template>
              <ng-template #error>{{'LOADING_COMPONENT_ERROR' | translate}}</ng-template>
              <ax-lazy-element [userid]="userId" [language]="selectedLanguage"
                               *axLazyElementDynamic="'top-lcs-krones' loadingTemplate: loading; errorTemplate: error">
              </ax-lazy-element>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
