import { AfterContentInit, Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {TopLanguageService} from '../../core/shared/language.service';

@Component({
  selector: 'top-dashboard-top-search',
  templateUrl: './top-search.component.html',
  styleUrls: ['./top-search.component.scss']
})
export class TopSearchComponent implements OnInit, AfterContentInit {

  public step: string;
  public userId: string;
  public language: string;
  public elementUrl = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translateService: TopLanguageService) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.userId = param.userId;
      this.language = param.language;
      this.step = '0';

      this.translateService.current.subscribe((lang) => {
        this.language = lang;
      });
    });
  }
  ngAfterContentInit(): void {
    this.elementUrl = environment.webComponents.topSearchUrl;
  }
}
