
export class TabProperties {
  constructor(url, label, attributeName, tabIndex, routeparameter, step) {
    this.url = url;
    this.label = label;
    this.attributeName = attributeName;
    this.tabIndex = tabIndex;
    this.routeparameter = routeparameter;
    this.step = step;
  }
  url: string;
  label: string;
  attributeName: string;
  tabIndex: number;
  routeparameter: string;
  step: number;
}
