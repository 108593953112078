import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'top-dashboard-show-my-tasks',
  templateUrl: './show-my-tasks.component.html',
  styleUrls: ['./show-my-tasks.component.scss']
})
export class ShowMyTasksComponent implements OnInit {
  @Input() userid: string;
  @Input() selectedLanguage: string;

  constructor() { }

  ngOnInit() {

  }

}
