import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AuthDialogData {
  title: string;
  userName: string;
  remainingTime: number;
}

@Component({
  selector: 'top-dashboard-auth-inactivity-dialog',
  templateUrl: './auth-inactivity-dialog.component.html',
  styleUrls: ['./auth-inactivity-dialog.component.scss']
})
export class AuthInactivityDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AuthInactivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthDialogData
  ) {
  }

  ngOnInit() {
  }

  onConfirm() {
    this.dialogRef.close();
  }
}
