import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TopLanguageService} from './core/shared/language.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotFoundComponent} from './error/not.found.component';
import {OverlayInDashboardRoot} from './overlay-in-dashboard-root';
import {OverlayContainer} from '@angular/cdk/overlay';
import {UserIdleModule} from 'angular-user-idle';
import {AuthInactivityDialogComponent} from './auth-inactivity-dialog/auth-inactivity-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {MatCardModule} from '@angular/material/card';
import {FormsModule} from '@angular/forms';
import {MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {HomeComponent} from './home/home.component';
import {ProfileComponent} from './profile/profile.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {LazyElementModuleOptions, LazyElementsModule} from '@angular-extensions/elements';
import {CommonModule} from '@angular/common';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TopSharedModule} from './core/shared/shared.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ShowMyTasksComponent} from './dashboard/show-my-tasks/show-my-tasks.component';
import {TopSignatureComponent} from './webComponents/top-signature/top-signature.component';
import {TopSearchComponent} from './webComponents/top-search/top-search.component';
import {UserService} from './core/services/user.service';

const uri = location.origin;
const msalConfig = {
  clientId: '6e1b9843-219a-4e35-9556-c5716e0961f2',
  authority: 'https://login.microsoftonline.com/9a3c2f68-d5b5-4769-b2f8-8d6fe142b164',
  redirectUri: uri,
  postLogoutRedirectUri: uri
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function HttpLoaderFactory(http: HttpClient) {
  const translateUrl = 'assets/i18n/';
  return new TranslateHttpLoader(http, translateUrl, '.json');
}

const options: LazyElementModuleOptions = {
  elementConfigs: [
    {tag: 'top-signature-dashboard', url: environment.webComponents.topSignatureUrl},
    {tag: 'top-search-dashboard', url: environment.webComponents.topSearchUrl},
    {tag: 'top-advert', url: environment.webComponents.topAdvertUrl},
    {tag: 'top-people-show', url: environment.webComponents.topPeopleUrl},
    {tag: 'top-lcs-krones', url: environment.webComponents.lcsKrones}
  ]
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NotFoundComponent,
    AuthInactivityDialogComponent,
    ShowMyTasksComponent,
    TopSignatureComponent,
    TopSearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    UserIdleModule.forRoot({
      idle: environment.idleTimeOut,
      timeout: environment.warningTime,
      ping: environment.pingToken
    }),
    MsalModule.forRoot(new PublicClientApplication({
      auth: msalConfig,
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, null),
    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    LazyElementsModule.forFeature(options),
    MatListModule,
    TranslateModule,
    MatTabsModule,
    MatMenuModule,
    MatSnackBarModule,
    TopSharedModule,
    DragDropModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    AuthInactivityDialogComponent
  ],
  providers: [
    TopLanguageService,
    TranslateService,
    UserService,
    {provide: OverlayContainer, useClass: OverlayInDashboardRoot}

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
