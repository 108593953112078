<div class="inactivity-dialog-container">
  <div class="dialog-item">
    <h1 class="dialog-title" mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <p class="dialog-content">Die Session für den Benutzer {{data.userName}} läuft in {{data.remainingTime}} Sekunden ab.</p>
    </div>
    <div class="dialog-btn">
      <button mat-raised-button color="primary" (click)="onConfirm()">Aktualisieren</button>
    </div>
  </div>
</div>
