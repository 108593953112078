<div class="task">
  <mat-card class="people">
    <mat-card-content>
      <ng-template #loading>{{'LOADING_COMPONENT' | translate}}</ng-template>
      <ng-template #error>{{'LOADING_COMPONENT_ERROR' | translate}}</ng-template>
      <ax-lazy-element [userid]="userid" [language]="selectedLanguage" authorization='{"generalAccess": "r", "hiddenFields": []}' *axLazyElementDynamic="'top-people-show' loadingTemplate: loading; errorTemplate: error"></ax-lazy-element>
    </mat-card-content>
  </mat-card>
</div>

