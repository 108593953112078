import { ModuleWithProviders, NgModule } from '@angular/core';

import { TopLanguageService } from './language.service';
import { SecondMinutesPipe } from '../pipes/second-minutes.pipe';

@NgModule({
  declarations: [SecondMinutesPipe],
  exports: [SecondMinutesPipe],
})
export class TopSharedModule {
  static forRoot(): ModuleWithProviders<TopSharedModule> {
    return {
      ngModule: TopSharedModule,
      providers: [TopLanguageService],
    };
  }
}
