import {Inject, Injectable, OnDestroy} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';

@Injectable({providedIn: 'root'})

export class OverlayInDashboardRoot extends OverlayContainer implements OnDestroy {
  constructor(@Inject(DOCUMENT) document: Document, _platform: Platform) {
    super(document, _platform);
  }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * Create the overlay container element, which is simply a div
     * with the 'cdk-overlay-container' class on the document body.
     */
    protected _createTopSignatureContainer(): void {
        const containerClass = 'cdk-overlay-container';
        const container = this._document.createElement('div');
        container.classList.add(containerClass);
        this._document.body.appendChild(container);
        this._containerElement = container;
    }

    getContainerElement(): HTMLElement {
        if (!this._containerElement) {
            this._createTopSignatureContainer();
        }
        return this._containerElement;
    }

}
